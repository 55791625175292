import {
  DateCalendar,
  DayCalendarSkeleton,
  LocalizationProvider,
  PickersDay,
  PickersDayProps,
  plPL,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import 'dayjs/locale/pl';
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import updateLocale from "dayjs/plugin/updateLocale";

export interface CustomerCalendarProps {
  isLoadingDates: boolean;
  availableDates: string[];
  selectedDate: Dayjs | null;
  setSelectedDate: Dispatch<SetStateAction<Dayjs | null>>;
  displayedMonth: Dayjs;
  setDisplayedMonth: Dispatch<SetStateAction<Dayjs>>;
}

export const CustomerCalendar = ({
  isLoadingDates,
  availableDates,
  selectedDate,
  setSelectedDate,
  displayedMonth,
  setDisplayedMonth,
}: CustomerCalendarProps) => {
  const [availableDays, setAvailableDays] = useState<number[]>();

  useEffect(() => {
    const dates = availableDates.map((date) => dayjs(date));
    const days = dates
      .filter((date) => date.month() === displayedMonth.month())
      .map((date) => date.date());
    setAvailableDays(days);
  }, [displayedMonth, availableDates]);

  const ServerDay = (
    props: PickersDayProps<Dayjs> & { availableDays?: number[] }
  ) => {
    const { availableDays = [], day, outsideCurrentMonth, ...other } = props;

    const isAvailable =
      !props.outsideCurrentMonth &&
      availableDays.indexOf(props.day.date()) >= 0;

    const dayStyle: React.CSSProperties = {
      border: isAvailable ? "1px solid #ddd" : undefined,
      fontWeight: "bold",
      borderRadius: "50%",
      padding: "6px",
    };

    return (
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
        disabled={!isAvailable}
        style={dayStyle}
      />
    );
  };

  dayjs.extend(updateLocale);
  dayjs.updateLocale("en", {
    weekStart: 1,
  });

  return (
    <>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale="pl"
        localeText={
          { ...plPL.components.MuiLocalizationProvider.defaultProps.localeText }
        }
      >
        <DateCalendar
          value={selectedDate}
          onChange={(newValue) => setSelectedDate(newValue)}
          onMonthChange={(displayedMonth) => setDisplayedMonth(displayedMonth)}
          views={["day"]}
          loading={isLoadingDates}
          renderLoading={() => <DayCalendarSkeleton />}
          slots={{
            day: ServerDay,
          }}
          slotProps={{
            day: {
              availableDays,
            } as any,
          }}
        />
      </LocalizationProvider>
    </>
  );
};
