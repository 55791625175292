import { RefObject } from "react";
import { CalendarDto } from "../models/DTO/calendar.dto";
import { Spinner } from "./Spinner";
import { useFetch } from "../hooks/useFetch";

export interface RemoveModalProps {
  dialogRef: RefObject<HTMLDialogElement>;
  calendarToBeRemoved: CalendarDto;
  onSuccess: () => void;
}

export const DeleteCalendarModal = ({
  dialogRef,
  calendarToBeRemoved,
  onSuccess,
}: RemoveModalProps) => {
  const { performFetch, isLoading } = useFetch();

  const deletionSuccessHandler = () => {
    onSuccess();
    dialogRef.current?.close();
  };

  const handleRemoveCalendar = async () => {
    await performFetch({
      method: "DELETE",
      path: `Calendar/${calendarToBeRemoved.id}`,
      onSuccess: deletionSuccessHandler,
    });
  };
  return (
    <>
      <dialog ref={dialogRef} className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Uwaga!</h3>
          <p className="py-4">
            Czy na pewno chcesz usunąć kalendarz{" "}
            <span className="font-bold">{calendarToBeRemoved.name}</span>?
          </p>
          <div className="modal-action">
            <button
              disabled={isLoading}
              className="btn btn-secondary"
              onClick={handleRemoveCalendar}
            >
              {!isLoading && "Usuń"} {isLoading && <Spinner />}
            </button>
            <form method="dialog">
              <button disabled={isLoading} className="btn">
                Anuluj
              </button>
            </form>
          </div>
        </div>
      </dialog>
    </>
  );
};
