import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";

export const SignInButton = () => {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance
      .loginPopup(loginRequest)
      .catch((e) => {
        console.log(e);
      })
      .then((response: any) => {
        if (response) instance.setActiveAccount(response.account);
      });
  };

  return (
    <button
      className="btn btn-outline btn-primary"
      onClick={() => handleLogin()}
    >
      Zaloguj się
    </button>
  );
};
