import { RefObject, useState } from "react";
import { Spinner } from "./Spinner";
import { CalendarDto } from "../models/DTO/calendar.dto";
import { useFetch } from "../hooks/useFetch";
import { toast } from "react-toastify";
import { TextInput } from "./inputs/TextInput";
import { CalendarReservationsType } from "../models/DTO/calendar-reservations-type.enum";
import { SelectInput, SelectInputOptionsProps } from "./inputs/SelectInput";

export interface CreateModalProps {
  dialogRef: RefObject<HTMLDialogElement>;
  onSuccess: () => void;
}

export const CreateCalendarModal = ({
  dialogRef,
  onSuccess,
}: CreateModalProps) => {
  const [newCalendarName, setNewCalendarName] = useState<string>("");
  const [newCalendarPageTitle, setNewCalendarPageTitle] = useState<string>("");
  // const [newCalendarReservationsType, setNewCalendarReservationsType] =
  //   useState<CalendarReservationsType>(0);
  const { performFetch, isLoading } = useFetch();

  const creationSuccessHandler = () => {
    onSuccess();
    setNewCalendarName("");
    setNewCalendarPageTitle("");
    // setNewCalendarReservationsType(0);
    toast.success("Utworzono kalendarz");
    dialogRef.current?.close();
  };

  const handleCreateCalendar = async () => {
    const body = JSON.stringify({
      name: newCalendarName,
      pageTitle: newCalendarPageTitle,
      pageDescription: "",
      // calendarReservationsType: newCalendarReservationsType,
    } as CalendarDto);
    await performFetch({
      method: "POST",
      path: "Calendar",
      body,
      onSuccess: creationSuccessHandler,
    });
  };

  const calendarOptions: SelectInputOptionsProps[] = [
    {
      key: CalendarReservationsType.WholeDays.toString(),
      label: "Rezerwacje całodniowe",
      value: CalendarReservationsType.WholeDays.toString(),
    },
    {
      key: CalendarReservationsType.Intervals.toString(),
      label: "Reserwacje interwałowe",
      value: CalendarReservationsType.Intervals.toString(),
    },
  ];

  return (
    <>
      {/* Create Calendar Modal */}
      <dialog ref={dialogRef} className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Utwórz kalendarz</h3>
          <TextInput
            label="Nazwa kalendarza"
            sublabel="Nazwa kalendarza widoczna w panelu administracyjnym"
            value={newCalendarName}
            setValue={setNewCalendarName}
          />
          <TextInput
            label="Tytuł strony kalendarza"
            sublabel="Tytuł widoczny na stronie kalendarza"
            value={newCalendarPageTitle}
            setValue={setNewCalendarPageTitle}
          />
          {/* <SelectInput
            label="Typ rezerwacji kalendarza"
            value={newCalendarReservationsType}
            setValue={setNewCalendarReservationsType}
            options={calendarOptions}
          /> */}

          <div className="modal-action">
            <button
              disabled={isLoading}
              className="btn btn-primary"
              onClick={handleCreateCalendar}
            >
              {!isLoading && "Utwórz"} {isLoading && <Spinner />}
            </button>
            <form method="dialog">
              <button disabled={isLoading} className="btn">
                Anuluj
              </button>
            </form>
          </div>
        </div>
      </dialog>
    </>
  );
};
