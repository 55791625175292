import { RefObject, useState } from "react";
import { TextInput } from "./inputs/TextInput";
import { ReservationsFieldType } from "../models/DTO/reservations-field-type.enum";
import { SelectInput, SelectInputOptionsProps } from "./inputs/SelectInput";
import { useFetch } from "../hooks/useFetch";
import { ReservationsFieldDto } from "../models/DTO/reservations-field.dto";
import { Spinner } from "./Spinner";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

export interface ReservationFieldModalProps {
  dialogRef: RefObject<HTMLDialogElement>;
  onSuccess: () => void;
}

export const ReservationFieldModal = ({dialogRef, onSuccess}: ReservationFieldModalProps) => {
  const { calendarId } = useParams();
  const [fieldName, setFieldName] = useState<string>("");
  const [newFieldType, setNewFieldType] =
    useState<ReservationsFieldType>(0);

  const { performFetch: createReservationField, isLoading } = useFetch();

  const fieldTypeOptions: SelectInputOptionsProps[] = [
    {
      key: ReservationsFieldType.Text.toString(),
      label: "Tekst",
      value: ReservationsFieldType.Text.toString(),
    },
    {
      key: ReservationsFieldType.Phone.toString(),
      label: "Nr telefonu",
      value: ReservationsFieldType.Phone.toString(),
    },
    {
      key: ReservationsFieldType.Email.toString(),
      label: "Email",
      value: ReservationsFieldType.Email.toString(),
    },
  ];

  const handleCreateSuccess = () => {
    onSuccess();
    setFieldName("");
    setNewFieldType(0);
    toast.success("Utworzono nowe pole");
    dialogRef.current?.close();
  }

  const handleAddReservationField = async () => {
    const body = JSON.stringify({
      name: fieldName,
      type: newFieldType
    } as ReservationsFieldDto);
    await createReservationField({ 
      method: "POST",
      path: `calendar/${calendarId}/field`,
      body,
      onSuccess: handleCreateSuccess
    });
  };

  return (
    <dialog ref={dialogRef} className="modal">
      <div className="modal-box">
        <h3 className="font-bold text-lg">Dodaj pole rezerwacji</h3>
        <TextInput
          label="Nazwa pola"
          sublabel="Nazwa pola widoczna w formularzu"
          value={fieldName}
          setValue={setFieldName}
        />
        <SelectInput
          label="Typ pola"
          value={newFieldType}
          setValue={setNewFieldType}
          options={fieldTypeOptions}
        />
        <div className="modal-action">
          <button
            disabled={isLoading}
            className="btn btn-primary"
            onClick={handleAddReservationField}
          >
            {!isLoading && "Dodaj"} {isLoading && <Spinner />}
          </button>
          <form method="dialog">
            <button disabled={isLoading} className="btn">
              Anuluj
            </button>
          </form>
        </div>
      </div>
    </dialog>
  );
}