import { Dispatch, SetStateAction } from "react";

export interface TextInputProps {
  label: string;
  sublabel?: string;
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
}

export const TextInput = ({
  label,
  sublabel,
  value,
  setValue,
}: TextInputProps) => {
  return (
    <label className="form-control w-full max-w-xs">
      <div className="label">
        <span className="label-text">{label}</span>
      </div>
      <input
        type="text"
        className="input input-bordered w-full max-w-xs"
        value={value}
        onChange={(x) => setValue(x.currentTarget.value)}
      />
      {sublabel && (
        <div className="label">
          <span className="label-text-alt">{sublabel}</span>
        </div>
      )}
    </label>
  );
};
