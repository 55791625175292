import { useEffect, useRef, useState } from "react";
import { DeleteCalendarModal } from "../DeleteCalendarModal";
import { useNavigate, useParams } from "react-router-dom";
import { CalendarDto } from "../../models/DTO/calendar.dto";
import { useFetch } from "../../hooks/useFetch";
import { toast } from "react-toastify";
import { Spinner } from "../Spinner";

export const RemoveCalendar = () => {
  const { calendarId } = useParams();
  const {
    result: calendar,
    isLoading,
    performFetch: getCalendar,
  } = useFetch<CalendarDto>();

  useEffect(() => {
    getCalendar({method: "GET", path: `calendar/${calendarId}`});
  }, []);

  const removeCalendarDialogRef = useRef<HTMLDialogElement>(null);

  const handleRemoveClick = () => {
    removeCalendarDialogRef.current?.showModal();
  };

  const navigate = useNavigate();
  const handleCalendarRemoved = () => {
    toast.success("Kalendarz został usunięty!");
    navigate('/panel');
  }

  return (
    <>
      <h2 className="font-bold">Usuń kalendarz</h2>
      <button
        className="btn btn-secondary"
        onClick={handleRemoveClick}
        disabled={isLoading}
      >
        {!isLoading && "Usuń"}{isLoading && <Spinner />}
      </button>
      {calendar && (
        <DeleteCalendarModal
          dialogRef={removeCalendarDialogRef}
          calendarToBeRemoved={calendar}
          onSuccess={handleCalendarRemoved}
        />
      )}
    </>
  );
};
