import { Link, Outlet, useParams } from "react-router-dom";
import { CalendarDto } from "../models/DTO/calendar.dto";
import { useFetch } from "../hooks/useFetch";
import { useEffect } from "react";

export const CalendarPage = () => {
  const { calendarId } = useParams();
  const {
    result: calendar,
    performFetch: getCalendar,
  } = useFetch<CalendarDto>();

  useEffect(() => {
    getCalendar({
      method: "GET",
      path: `calendar/${calendarId}`
    });
  }, []);

  return (
    <div className="bg-base-100 shadow-xl rounded-box divide-x">
      <div className="inline-block align-top p-4">
        <ul className="menu bg-base-200 w-56 rounded-box inline-block">
          <li>
            <Link to={`/panel/calendar/${calendarId}/settings`}>Ogólne</Link>
          </li>
          <li>
            <Link to={`/panel/calendar/${calendarId}/settings/availability`}>
              Ustawienia rezerwacji
            </Link>
          </li>
          <li>
            <Link to={`/panel/calendar/${calendarId}/settings/fields`}>
              Dane rezerwacji
            </Link>
          </li>
          <li>
            <Link to={`/panel/calendar/${calendarId}/settings/remove`}>
              Usuń kalendarz
            </Link>
          </li>
        </ul>
      </div>
      <div className="inline-block p-4 pt-2">
        <div className="text-sm breadcrumbs mb-2">
          <ul>
            <li>
              <Link to="/panel">Kalendarze</Link>
            </li>
            <li>{calendar?.name}</li>
          </ul>
        </div>
        <Outlet />
      </div>
    </div>
  );
};
