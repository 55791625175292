import { Link, useParams } from "react-router-dom";
import { useFetch } from "../hooks/useFetch";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { CalendarDto } from "../models/DTO/calendar.dto";
import { ReservationDto } from "../models/DTO/reservation.dto";
import dayjs from "dayjs";
import { ReservationsFieldDto } from "../models/DTO/reservations-field.dto";
import { ReservationFieldValueDto } from "../models/DTO/reservation-field-value.dto";

export const ReservationsPage = () => {
  const { calendarId } = useParams();
  const { result: calendar, performFetch: getCalendar } =
    useFetch<CalendarDto>();

  const { isLoading: isApproving, performFetch: approveReservation } =
    useFetch<CalendarDto>();

  const { isLoading: isRejecting, performFetch: rejectReservation } =
    useFetch<CalendarDto>();

  const [appliedFilter, setAppliedFilter] = useState<
    "New" | "Approved" | "Rejected"
  >("New");

  const [filteredReservations, setFilteredReservations] =
    useState<ReservationDto[]>();

  useEffect(() => {
    getCalendar({
      method: "GET",
      path: `calendar/${calendarId}`,
    });
    fetchReservations();
    fetchFields();
  }, []);

  const { result: fields, performFetch: getFields } =
    useFetch<ReservationsFieldDto[]>();

  const fetchFields = useCallback(() => {
    getFields({
      method: "GET",
      path: `calendar/${calendarId}/field/all`,
    });
  }, []);

  const { result: reservations, performFetch: getReservations } =
    useFetch<ReservationDto[]>();

  const handleApproveClick = (reservationId: string) => {
    approveReservation({
      method: "POST",
      path: `calendar/${calendarId}/reservation/${reservationId}/approve`,
      onSuccess: fetchReservations,
    });
  };

  const handleRejectClick = (reservationId: string) => {
    rejectReservation({
      method: "POST",
      path: `calendar/${calendarId}/reservation/${reservationId}/reject`,
      onSuccess: fetchReservations,
    });
  };

  const fetchReservations = () => {
    getReservations({
      method: "GET",
      path: `calendar/${calendarId}/reservation`,
    });
  };

  const getFieldText = (value: ReservationFieldValueDto): ReactNode => {
    const field = fields!.filter((f) => f.id === value.fieldId);
    if (field.length == 0) return "";

    return (
      <>
        <b>{field[0].name}</b>: {value.value}
      </>
    );
  };

  useEffect(() => {
    const baseReservations = reservations ? reservations : [];

    const filterReservations = (
      reservations: ReservationDto[]
    ): ReservationDto[] => {
      return reservations.filter((x) =>
        appliedFilter === "New"
          ? x.state === 0
          : appliedFilter === "Approved"
          ? x.state === 1
          : x.state === 2
      );
    };

    setFilteredReservations(filterReservations(baseReservations));
  }, [reservations, appliedFilter]);

  return (
    <div className="bg-base-100 shadow-xl rounded-box p-4">
      <div className="p-4 pt-0">
        <div className="text-sm breadcrumbs mb-2">
          <ul>
            <li>
              <Link to="/panel">Kalendarze</Link>
            </li>
            <li>{calendar?.name}</li>
          </ul>
        </div>
        <h2 className="text-xl font-bold">Rezerwacje</h2>
      </div>

      {filteredReservations && fields && (
        <div className="overflow-x-auto">
          {/* Filters start */}
          <div className="grid grid-flow-col auto-cols-min gap-2 p-4">
            <div
              className={`cursor-pointer badge badge-primary badge-lg ${
                appliedFilter !== "New" ? "badge-outline" : ""
              }`}
              onClick={() => setAppliedFilter("New")}
            >
              Nowe
            </div>
            <div
              className={`cursor-pointer badge badge-primary badge-lg ${
                appliedFilter !== "Approved" ? "badge-outline" : ""
              }`}
              onClick={() => setAppliedFilter("Approved")}
            >
              Zaakceptowane
            </div>
            <div
              className={`cursor-pointer badge badge-primary badge-lg ${
                appliedFilter !== "Rejected" ? "badge-outline" : ""
              }`}
              onClick={() => setAppliedFilter("Rejected")}
            >
              Odrzucone
            </div>
          </div>
          {/* Filters end */}
          {/* Timeline start */}
          <ul className="timeline timeline-vertical">
            {filteredReservations.map((x, i) => (
              <li>
                {i > 0 && <hr />}
                <div className="timeline-start">
                  {dayjs(x.date).format("DD/MM/YYYY")}
                </div>
                <div className="timeline-middle">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className={`${
                      x.state === 1
                        ? "text-success"
                        : x.state === 2
                        ? "text-error"
                        : ""
                    } h-5 w-5`}
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <div className="timeline-end timeline-box">
                  <div className="my-2">
                    {x.values.map((value) => (
                      <div>{getFieldText(value)}</div>
                    ))}
                  </div>
                  <div className="my-2">
                    {x.state !== 1 && (
                      <button
                        onClick={() => handleApproveClick(x.id)}
                        className="btn btn-sm btn-success mr-4"
                      >
                        Zaakceptuj
                      </button>
                    )}
                    {x.state !== 2 && (
                      <button
                        onClick={() => handleRejectClick(x.id)}
                        className="btn btn-sm btn-error mr-4"
                        disabled={x.state == 2}
                      >
                        Odrzuć
                      </button>
                    )}
                  </div>
                </div>
                {i < filteredReservations.length - 1 && <hr />}
              </li>
            ))}
          </ul>
          {/* Timeline end */}
        </div>
      )}
    </div>
  );
};
