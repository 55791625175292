import { RefObject } from "react";
import { CustomerReservationDto } from "../../models/DTO/customer-reservation.dto";
import dayjs from "dayjs";

export interface ReservationCreatedPopupProps {
  dialogRef: RefObject<HTMLDialogElement>;
  createdReservation: CustomerReservationDto | undefined;
}

export const ReservationCreatedPopup = ({dialogRef, createdReservation}: ReservationCreatedPopupProps) => {

  return (
    <>
      <dialog ref={dialogRef} id="my_modal_3" className="modal">
        <div className="modal-box">
          <form method="dialog">
            {/* if there is a button in form, it will close the modal */}
            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
              ✕
            </button>
          </form>
          <h3 className="font-bold text-lg">Dziękuję za rezerwację!</h3>
          <p className="py-4">Twoja rezerwacja na dzień <span className="font-bold">{createdReservation?.date && dayjs(createdReservation!.date).format("DD/MM/YYYY")}</span> została utworzona.</p>
        </div>
      </dialog>
    </>
  );
}