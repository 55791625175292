import { useState } from "react";

export interface CheckboxInputProps {
  label: string;
  value: any;
  onChange: (isChecked: boolean, value: any) => void;
  initialCheckedStatus: boolean;
}

export const CheckboxInput = ({
  label,
  value,
  onChange,
  initialCheckedStatus,
}: CheckboxInputProps) => {
  const [isChecked, setIsChecked] = useState<boolean>(initialCheckedStatus);

  const handleChange = () => {
    const newIsChecked = !isChecked;
    setIsChecked(newIsChecked);
    onChange(newIsChecked, value);
  };

  return (
    <div className="form-control items-start">
      <label className="cursor-pointer label">
        <input
          type="checkbox"
          className="checkbox checkbox-success mr-2"
          checked={isChecked}
          onChange={handleChange}
        />
        <span className="label-text">{label}</span>
      </label>
    </div>
  );
};
