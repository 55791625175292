import { useEffect, useState } from "react";
import { CheckboxInput } from "../inputs/CheckboxInput";
import { useFetch } from "../../hooks/useFetch";
import { CalendarDto } from "../../models/DTO/calendar.dto";
import { Spinner } from "../Spinner";
import { useParams } from "react-router-dom";
import { CalendarAvailabilityDto } from "../../models/DTO/calendar-availability.dto";
import { toast } from "react-toastify";

export const WholeDayAvailabilitySettings = () => {
  const { calendarId } = useParams();
  const [selectedDays, setSelectedDays] = useState<number[]>([]);

  const { isLoading, performFetch: getCalendarAvailability } =
    useFetch<CalendarDto>();
  const {
    isLoading: isUpdating,
    performFetch: updateCalendarAvailablility,
    ok,
  } = useFetch<CalendarDto>();

  const handleDayChange = (isChecked: boolean, day: number) => {
    const index = selectedDays.indexOf(day);
    const newSelectedDays = [...selectedDays];
    //add new element if it doesn't exist yet
    if (isChecked) {
      if (index > -1) {
        return;
      }
      newSelectedDays.push(day);
      newSelectedDays.sort();
      setSelectedDays(newSelectedDays);
      return;
    }

    //remove element
    if (index > -1) {
      newSelectedDays.splice(index, 1);
      setSelectedDays(newSelectedDays);
    }
  };

  useEffect(() => {
    getCalendarAvailability({
      method: "GET",
      path: `calendarAvailabilitySettings/${calendarId}`,
      onSuccess: handleGetCalendarAvailabilitySuccess,
    });
  }, []);

  const handleGetCalendarAvailabilitySuccess = (
    calendar: CalendarAvailabilityDto
  ) => {
    setSelectedDays(calendar.availabilityDays);
  };

  const handleSaveClick = () => {
    const body: string = JSON.stringify({
      calendarId: calendarId,
      availabilityDays: selectedDays,
    } as CalendarAvailabilityDto);
    updateCalendarAvailablility({
      method: "PUT",
      path: `calendarAvailabilitySettings/${calendarId}`,
      body,
      onSuccess: () =>
        toast.success(
          "Poprawnie zaktualizowanie ustawienia dostepności kalendarza"
        ),
    });
  };

  return (
    <>
      {isLoading && <Spinner />}
      {!isLoading && (
        <>
          <h2 className="font-bold">Dni dostępne do rezerwacji</h2>
          <CheckboxInput
            value={0}
            onChange={handleDayChange}
            label="Poniedziałek"
            initialCheckedStatus={selectedDays.indexOf(0) > -1}
          />
          <CheckboxInput
            value={1}
            onChange={handleDayChange}
            label="Wtorek"
            initialCheckedStatus={selectedDays.indexOf(1) > -1}
          />
          <CheckboxInput
            value={2}
            onChange={handleDayChange}
            label="Środa"
            initialCheckedStatus={selectedDays.indexOf(2) > -1}
          />
          <CheckboxInput
            value={3}
            onChange={handleDayChange}
            label="Czwartek"
            initialCheckedStatus={selectedDays.indexOf(3) > -1}
          />
          <CheckboxInput
            value={4}
            onChange={handleDayChange}
            label="Piątek"
            initialCheckedStatus={selectedDays.indexOf(4) > -1}
          />
          <CheckboxInput
            value={5}
            onChange={handleDayChange}
            label="Sobota"
            initialCheckedStatus={selectedDays.indexOf(5) > -1}
          />
          <CheckboxInput
            value={6}
            onChange={handleDayChange}
            label="Niedziela"
            initialCheckedStatus={selectedDays.indexOf(6) > -1}
          />
          <button
            disabled={isUpdating}
            className="btn btn-primary mt-3"
            onClick={handleSaveClick}
          >
            {!isUpdating && "Zapisz"} {isUpdating && <Spinner />}
          </button>
        </>
      )}
    </>
  );
};
