import { toast } from "react-toastify";
import { useState } from "react";

export interface performFetchParams {
  method: "GET" | "POST" | "DELETE" | "PUT";
  path: string;
  onSuccess?: (json: any) => void;
  body?: string;
}

export const useAnonymousFetch = <T,>() => {
  const [result, setResult] = useState<T>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [ok, setOk] = useState<boolean>();

  const performFetch = async ({
    method,
    path,
    onSuccess,
    body,
  }: performFetchParams) => {
    setResult(undefined);
    setOk(undefined);
    setIsLoading(true);
    try {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");

      let options: RequestInit = {
        method: method,
        headers: headers,
      };

      if (body) {
        options = { ...options, body: body };
      }

      const fetchResult = await fetch(
        `${window._env_.REACT_APP_API_URL}${path}`,
        options
      );
      setOk(fetchResult.ok);

      let json;
      if ((method === "GET" || method === "POST") && fetchResult.ok) {
        json = await fetchResult.json();
        setResult(json as T);
      }

      if (!fetchResult.ok) {
        const json = await fetchResult.json();
        throw new Error(json.detail);
      }

      if (onSuccess) onSuccess(json);
    } catch (error) {
      console.error(error);
      setOk(false);
      if (error instanceof Error) toast.error(`${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return { performFetch, result, isLoading, ok };
};
