import { useCallback, useEffect, useRef } from "react";
import { CreateCalendarModal } from "./CreateCalendarModal";
import { CalendarDto } from "../models/DTO/calendar.dto";
import { Spinner } from "./Spinner";
import { useFetch } from "../hooks/useFetch";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faGear,
  faListCheck,
} from "@fortawesome/free-solid-svg-icons";

export const CalendarsTable = () => {
  const {
    result: calendars,
    performFetch,
    isLoading,
  } = useFetch<CalendarDto[]>();
  const navigate = useNavigate();

  const getCalendars = useCallback(async () => {
    await performFetch({ method: "GET", path: "Calendar" });
  }, []);

  const createCalendarDialogRef = useRef<HTMLDialogElement>(null);

  const handleCreateClick = () => {
    createCalendarDialogRef.current?.showModal();
  };

  useEffect(() => {
    getCalendars();
  }, []);

  const handleManageReservations = (calendar: CalendarDto): void => {
    navigate(`calendar/${calendar.id}/reservations`);
  };

  const handleSettingsClick = (calendar: CalendarDto) => {
    navigate(`calendar/${calendar.id}/settings`);
  };

  const copyCalendarUrlToClipboard = (calendar: CalendarDto) => {
    window.open(`${window.location.origin}/${calendar.id}`, "_blank")?.focus();
  };

  return (
    <>
      <div className="flex flex-row p-4 mb-3">
        <h2 className="text-xl font-bold">Kalendarze</h2>
        <button className="btn btn-primary ml-auto" onClick={handleCreateClick}>
          Utwórz kalendarz
        </button>
      </div>
      {isLoading && <Spinner />}
      {!isLoading && calendars && (
        <div className="overflow-x-auto">
          <table className="table">
            <thead>
              <tr>
                <th className="w-5"></th>
                <th>Nazwa</th>
                <th className="w-52"></th>
              </tr>
            </thead>
            <tbody>
              {calendars.map((x: CalendarDto, i: number) => (
                <tr className="hover" key={x.id}>
                  <th>{i + 1}</th>
                  <td>{x.name}</td>
                  <td>
                    <button
                      className="btn btn-outline btn-primary mr-4 relative"
                      onClick={() => handleManageReservations(x)}
                    >
                      <>
                        <FontAwesomeIcon icon={faListCheck} />
                        {!!x.numberOfPendingReservations && (
                          <div className="badge badge-error badge-sm absolute -top-2 -right-2">
                            {x.numberOfPendingReservations}
                          </div>
                        )}
                      </>
                    </button>
                    <button
                      className="btn btn-outline btn-accent mr-4"
                      onClick={() => copyCalendarUrlToClipboard(x)}
                    >
                      <FontAwesomeIcon icon={faCalendar} />
                    </button>
                    <button
                      className="btn btn-outline btn-secondary"
                      onClick={() => handleSettingsClick(x)}
                    >
                      <FontAwesomeIcon icon={faGear} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <CreateCalendarModal
        dialogRef={createCalendarDialogRef}
        onSuccess={getCalendars}
      />
    </>
  );
};
