import { useMsal } from "@azure/msal-react";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

export const Navbar = () => {
  const { instance } = useMsal();

  const handleLogout = () => {
    instance.logoutPopup({
      postLogoutRedirectUri: "/",
      mainWindowRedirectUri: "/",
    });
  };

  return (
    <div className="navbar bg-base-100 shadow-xl rounded-box px-4">
      <div className="flex-1">
        <h1 className="text-xl font-bold tracking-wide px-4 text-base-content">
          Cally.pl
        </h1>
      </div>
      <div className="flex-none">
        <ul className="menu menu-horizontal px-1">
          <li>
            <Link to="/panel">Kalendarze</Link>
          </li>
        </ul>
      </div>
      <div className="dropdown dropdown-end">
        <div tabIndex={0} role="button" className="btn btn-ghost btn-circle">
          <div className="w-10 rounded-full">
            <div className="w-full h-full flex justify-center items-center">
              <p className="text-xl">
                <FontAwesomeIcon icon={faGear} />
              </p>
            </div>
          </div>
        </div>
        <ul
          tabIndex={0}
          className="mt-3 z-[1] p-2 shadow menu menu-sm dropdown-content bg-base-100 rounded-box w-52"
        >
          <li>
            <a onClick={handleLogout}>Wyloguj</a>
          </li>
        </ul>
      </div>
    </div>
  );
};
