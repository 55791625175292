import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error: any = useRouteError();

  return (
    <div
      className="flex flex-col h-svh w-svw justify-center items-center"
      id="error-page"
    >
      <h1 className="text-2xl font-bold mb-2">Ups!</h1>
      <p className="mb-5">Wystąpił błąd.</p>
      <p className="italic">{error.statusText || error.message}</p>
    </div>
  );
}
