import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useAnonymousFetch } from "../hooks/useAnonymousFetch";
import { ToastContainer } from "react-toastify";
import { isValidGuid } from "../utils/guid-validator";
import { CustomerCalendarDto } from "../models/DTO/customer-calendar.dto";
import { CustomerCalendar } from "../components/customer/CustomerCalendar";
import dayjs, { Dayjs } from "dayjs";
import { CreateReservationForm } from "../components/customer/CreateReservationForm";
import { ReservationCreatedPopup } from "../components/customer/ReservationCreatedPopup";
import { CustomerReservationDto } from "../models/DTO/customer-reservation.dto";

export const CustomerCalendarPage = () => {
  const { calendarId } = useParams();
  const {
    result: calendar,
    performFetch,
    isLoading,
  } = useAnonymousFetch<CustomerCalendarDto>();
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [displayedMonth, setDisplayedMonth] = useState<Dayjs>(dayjs());
  const [createdReservation, setCreatedReservation] = useState<CustomerReservationDto>();

  const {
    result: availableDates,
    performFetch: getAvailableDates,
    isLoading: isLoadingDates,
  } = useAnonymousFetch<string[]>();

  useEffect(() => {
    if (calendarId && isValidGuid(calendarId)) {
      performFetch({ method: "GET", path: `Customer/Calendar/${calendarId}` });
    } else {
      throw new Error("Podana strona nie istnieje");
    }
  }, []);

  const refetchDates = useCallback(async () => {
    const apiAcceptableDateFormat = "YYYY-MM-DDTHH:mm:ss";
    await getAvailableDates({
      path: `Customer/CalendarAvailability/dates/${calendarId}/${displayedMonth.format(
        apiAcceptableDateFormat
      )}`,
      method: "GET",
    });
  }, [calendarId, displayedMonth]);

  useEffect(() => {
    const filteredDates = availableDates?.filter(x => dayjs(x).format("YYYY-MM-DD") === selectedDate?.format("YYYY-MM-DD"));
    const isSelectedDayAvailable = filteredDates && filteredDates?.length > 0;
    if(!isSelectedDayAvailable) {
      setSelectedDate(null);
    }
  }, [availableDates])

  useEffect(() => {
    refetchDates();
  }, [displayedMonth, refetchDates]);

  const createdDialogRef = useRef<HTMLDialogElement>(null);

  const handleReservationCreated = async (reservation: CustomerReservationDto) => {
    setCreatedReservation(reservation);
    await refetchDates();
    createdDialogRef.current?.showModal();
  }

  return (
    <>
      <ToastContainer />
      <div className="flex flex-col justify-center items-center">
        {isLoading && (
          <div className="flex flex-col gap-4 w-52 m-3">
            <div className="skeleton h-4 w-28 self-center"></div>
            <div className="skeleton h-4 w-full"></div>
          </div>
        )}
        {!isLoading && calendar && (
          <>
            <h1 className="text-2xl font-bold">{calendar.pageTitle}</h1>
            <h2 className="text-md">{calendar.pageDescription}</h2>
          </>
        )}
        <CustomerCalendar
          isLoadingDates={isLoadingDates}
          availableDates={availableDates ?? []}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          displayedMonth={displayedMonth}
          setDisplayedMonth={setDisplayedMonth}
        />
        {/* <CustomerTimeslotPicker
            calendarId={calendarId!}
            selectedDate={selectedDate}
          /> */}
        {!isLoading && calendar && (
          <CreateReservationForm
            calendar={calendar}
            selectedDate={selectedDate}
            onReservationCreated={handleReservationCreated}
            calendarId={calendarId!}
          />
        )}
      </div>
      <ReservationCreatedPopup
        dialogRef={createdDialogRef}
        createdReservation={createdReservation}
      />
    </>
  );
};
