export interface CustomerTextInputProps {
  label: string;
  value: string;
  fieldId: string;
  onChange: (key: string, value: string) => void;
}

export const CustomerTextInput = ({
  label,
  value,
  fieldId,
  onChange
}: CustomerTextInputProps) => {
  return (
    <label className="form-control w-full max-w-xs">
      <div className="label">
        <span className="label-text">{label}</span>
      </div>
      <input
        type="text"
        className="input input-bordered w-full max-w-xs"
        value={value}
        onChange={(x) => onChange(fieldId, x.currentTarget.value)}
      />
    </label>
  );
};
