import {
  useNavigate,
  createBrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { LoginPage } from "./pages/LoginPage";
import ErrorPage from "./pages/ErrorPage";
import { CustomerCalendarPage } from "./pages/CustomerCalendarPage";
import { PanelPage } from "./panel-pages/PanelPage";
import { DashboardPage } from "./panel-pages/DashboardPage";
import { ReservationsPage } from "./panel-pages/ReservationsPage";
import { CalendarPage } from "./panel-pages/CalendarPage";
import { CalendarSettings } from "./components/calendar-settings/CalendarSettings";
import { WholeDayAvailabilitySettings } from "./components/calendar-settings/WholeDayAvailabilitySettings";
import { ReservationFieldsSettings } from "./components/calendar-settings/ReservationFieldsSettings";
import { RemoveCalendar } from "./components/calendar-settings/RemoveCalendar";
import { CustomNavigationClient } from "./utils/navigation-client";

type AppProps = {
  pca: IPublicClientApplication;
};

export const App = ({ pca }: AppProps) => {
  // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  return (
    <MsalProvider instance={pca}>
      <Routes>
        <Route path="/" element={<LoginPage />} errorElement={<ErrorPage />} />
        <Route
          path="/:calendarId"
          element={<CustomerCalendarPage />}
          errorElement={<ErrorPage />}
        />
        <Route path="/panel" element={<PanelPage />}>
          <Route path="" element={<DashboardPage />} />
          <Route
            path="calendar/:calendarId/reservations"
            element={<ReservationsPage />}
          />
          <Route
            path="calendar/:calendarId/settings"
            element={<CalendarPage />}
          >
            <Route path="" element={<CalendarSettings />} />
            <Route
              path="availability"
              element={<WholeDayAvailabilitySettings />}
            />
            <Route path="fields" element={<ReservationFieldsSettings />} />
            <Route path="remove" element={<RemoveCalendar />} />
          </Route>
        </Route>
      </Routes>
    </MsalProvider>
  );
};
