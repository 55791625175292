import { ChangeEvent, Dispatch, SetStateAction, useState } from "react";

export interface SelectInputProps {
  label: string;
  sublabel?: string;
  placeholder?: string;
  value: any;
  setValue: Dispatch<SetStateAction<any>>;
  options: SelectInputOptionsProps[];
}

export interface SelectInputOptionsProps {
  key: string;
  label: string;
  value: string;
}

export const SelectInput = ({
  label,
  sublabel,
  value,
  setValue,
  options,
  placeholder,
}: SelectInputProps) => {
  return (
    <label className="form-control w-full max-w-xs">
      <div className="label">
        <span className="label-text">{label}</span>
      </div>
      <select
        className="select select-bordered"
        onChange={(event: ChangeEvent<HTMLSelectElement>) => {
          setValue(event.target.value);
        }}
        value={value}
      >
        <option disabled selected value={undefined} hidden>
          {placeholder ?? ""}
        </option>
        {options.map((option: SelectInputOptionsProps) => {
          return (
            <option key={option.key} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </select>
      <div className="label">
        <span className="label-text-alt">{sublabel}</span>
      </div>
    </label>
  );
};
