import { RefObject } from "react";
import { Spinner } from "./Spinner";
import { toast } from "react-toastify";
import { useFetch } from "../hooks/useFetch";
import { ReservationsFieldDto } from "../models/DTO/reservations-field.dto";
import { useParams } from "react-router-dom";

export interface DeleteReservationFieldModalProps {
  dialogRef: RefObject<HTMLDialogElement>;
  fieldToBeRemoved: ReservationsFieldDto;
  onSuccess: () => void;
}

export const DeleteReservationFieldModal = ({
  dialogRef,
  fieldToBeRemoved,
  onSuccess,
}: DeleteReservationFieldModalProps) => {
  const { performFetch, isLoading } = useFetch();
  const {calendarId} = useParams();

  const deletionSuccessHandler = () => {
    onSuccess();
    toast.success("Usunięto pole");
    dialogRef.current?.close();
  };

  const handleRemoveReservationField = async () => {
    await performFetch({
      method: "DELETE",
      path: `Calendar/${calendarId}/field/${fieldToBeRemoved.id}`,
      onSuccess: deletionSuccessHandler,
    });
  };
  return (
    <>
      <dialog ref={dialogRef} className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Uwaga!</h3>
          <p className="py-4">
            Czy na pewno chcesz usunąć pole{" "}
            <span className="font-bold">{fieldToBeRemoved.name}</span>?
          </p>
          <div className="modal-action">
            <button
              disabled={isLoading}
              className="btn btn-secondary"
              onClick={handleRemoveReservationField}
            >
              {!isLoading && "Usuń"} {isLoading && <Spinner />}
            </button>
            <form method="dialog">
              <button disabled={isLoading} className="btn">
                Anuluj
              </button>
            </form>
          </div>
        </div>
      </dialog>
    </>
  );
};
