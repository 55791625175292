import { Dayjs } from "dayjs";
import { CustomerCalendarDto } from "../../models/DTO/customer-calendar.dto";
import { CustomerReservationDto } from "../../models/DTO/customer-reservation.dto";
import { Spinner } from "../Spinner";
import { useAnonymousFetch } from "../../hooks/useAnonymousFetch";
import { useEffect, useState } from "react";
import { CustomerReservationsField } from "../../models/DTO/customer-reservations-field.dto";
import { CustomerTextInput } from "./CustomerTextInput";

interface CreateReservationFormProps {
  calendar: CustomerCalendarDto;
  selectedDate: Dayjs | null;
  onReservationCreated: (reservation: CustomerReservationDto) => void;
  calendarId: string;
}

interface FormField {
  fieldId: string;
  name: string;
  value: string
}

export const CreateReservationForm = ({
  calendar,
  selectedDate,
  onReservationCreated,
  calendarId
}: CreateReservationFormProps) => {
  const apiAcceptableDateFormat = "YYYY-MM-DDTHH:mm:ss";

  const {
    isLoading: isCreating,
    performFetch: createReservation
  } = useAnonymousFetch<CustomerReservationDto>();

  const handleCreateReservation = () => {
    const reservation = {
      calendarId: calendar.id,
      date: selectedDate?.format(apiAcceptableDateFormat),
      form: formFields
    } as CustomerReservationDto;
    createReservation({
      method: "POST",
      path: `customer/reservation`,
      body: JSON.stringify(reservation),
      onSuccess: onReservationCreated
    })
  };

  const {isLoading: isLoadingFields, performFetch: fetchFields} = useAnonymousFetch<CustomerReservationsField[]>();
  const [formFields, setFormFields] = useState<FormField[]>([]);

  const handleFormFieldsFetchSuccess = (x: CustomerReservationsField[]) => {
    const formFields = x.map((y) => ({ fieldId: y.id, name: y.name, value: '' }));
    setFormFields(formFields);
  };

  useEffect(() => {
    fetchFields({
      method: "GET",
      path: `customer/calendar/${calendarId}/fields`,
      onSuccess: handleFormFieldsFetchSuccess,
    });
  }, []);

  const handleInputChange = (fieldId: string, value: string) => {
    const formFieldsCopy: FormField[] = JSON.parse(JSON.stringify(formFields));
    const field = formFieldsCopy.filter(x => x.fieldId === fieldId);
    field[0].value = value;
    setFormFields(formFieldsCopy);
  }

  return (
    <>
      {formFields.map((x) => (
        <CustomerTextInput key={x.fieldId} label={x.name} value={x.value} fieldId={x.fieldId} onChange={handleInputChange} />
      ))}

      <button
        disabled={isCreating || selectedDate === null}
        className="btn btn-primary mt-3"
        onClick={handleCreateReservation}
      >
        {!isCreating && "Zarezerwuj"} {isCreating && <Spinner />}
      </button>
    </>
  );
};
