import { useParams } from "react-router-dom";
import { useFetch } from "../../hooks/useFetch";
import { ReservationsFieldDto } from "../../models/DTO/reservations-field.dto";
import { ReservationFieldModal } from "../ReservationFieldModal";
import { useCallback, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";
import { DeleteReservationFieldModal } from "../DeleteReservationFieldModal";

export const ReservationFieldsSettings = () => {
  const dialogRef = useRef<HTMLDialogElement>(null);
  const deleteDialogRef = useRef<HTMLDialogElement>(null);
  const {calendarId} = useParams();
  const [fields, setFields] = useState<ReservationsFieldDto[]>([]);
  const [selectedField, setSelectedField] = useState<ReservationsFieldDto>({} as ReservationsFieldDto);

  const handleAddFieldClick = () => {
    dialogRef.current?.showModal();
  }

  const {performFetch: getFields, isLoading} = useFetch<ReservationsFieldDto>();

  const fetchFields = useCallback(() => {
    getFields({
      method: "GET",
      path: `calendar/${calendarId}/field`,
      onSuccess: handleGetFieldsSuccess,
    })},[]);

  useEffect(() => {
    fetchFields();
  }, []);

  const handleGetFieldsSuccess = (fields: ReservationsFieldDto[]) => {
    setFields(fields);
  }

  const handleDeleteFieldClick = (field: ReservationsFieldDto) => {
    setSelectedField(field);
    deleteDialogRef.current?.showModal();
  }

  return (
    <>
      <h2 className="font-bold">Ustawienia pól rezerwacji</h2>
      <div>
        <div className="overflow-x-auto">
          <table className="table">
            {/* head */}
            <thead>
              <tr>
                <th>Nazwa</th>
                <th>Typ</th>
                <th>Akcje</th>
              </tr>
            </thead>
            <tbody>
              {fields.map((field) => (
                <tr className="hover">
                  <td>{field.name}</td>
                  <td>{field.type}</td>
                  <td>
                    <button className="btn btn-secondary" onClick={() => handleDeleteFieldClick(field)}>
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <button className="btn btn-link" onClick={handleAddFieldClick}>
          + Dodaj nowe pole
        </button>
      </div>
      <ReservationFieldModal
        dialogRef={dialogRef}
        onSuccess={fetchFields}
      />
      <DeleteReservationFieldModal
        dialogRef={deleteDialogRef}
        onSuccess={fetchFields}
        fieldToBeRemoved={selectedField} 
      />
    </>
  );
};
