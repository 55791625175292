import { useEffect, useState } from "react";
import { useFetch } from "../../hooks/useFetch";
import { CalendarDto } from "../../models/DTO/calendar.dto";
import { Spinner } from "../Spinner";
import { TextInput } from "../inputs/TextInput";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

export const CalendarSettings = () => {
  const { calendarId } = useParams();

  const {
    result: calendar,
    isLoading,
    performFetch: getCalendar,
  } = useFetch<CalendarDto>();
  const { isLoading: isUpdating, performFetch: updateCalendar } =
    useFetch<CalendarDto>();
  const [calendarName, setCalendarName] = useState<string>("");
  const [calendarPageTitle, setCalendarPageTitle] = useState<string>("");
  const [calendarPageDescription, setCalendarPageDescription] =
    useState<string>("");

  useEffect(() => {
    getCalendar({
      method: "GET",
      path: `calendar/${calendarId}`,
      onSuccess: handleGetCalendarSuccess,
    });
  }, []);

  const handleGetCalendarSuccess = (calendar: CalendarDto) => {
    setCalendarName(calendar.name);
    setCalendarPageTitle(calendar.pageTitle);
    setCalendarPageDescription(calendar.pageDescription);
  };

  const handleUpdateCalendar = () => {
    const body: string = JSON.stringify({
      name: calendarName,
      pageTitle: calendarPageTitle,
      pageDescription: calendarPageDescription,
    } as CalendarDto);
    updateCalendar({
      method: "PUT",
      path: `calendar/${calendarId}`,
      body,
      onSuccess: handleCalendarUpdateSuccess,
    });
  };

  const handleCalendarUpdateSuccess = () => {
    toast.success("Poprawnie zaktualizowano dane kalendarza");
  };

  return (
    <>
      {isLoading && <Spinner />}
      {!isLoading && calendar && (
        <>
          <h2 className="font-bold">Ustawienia kalendarza</h2>
          <TextInput
            label="Nazwa kalendarza"
            value={calendarName}
            setValue={setCalendarName}
          />

          <TextInput
            label="Tytuł strony kalendarza"
            sublabel="Tytuł widoczny na stronie kalendarza"
            value={calendarPageTitle}
            setValue={setCalendarPageTitle}
          />

          <TextInput
            label="Opis strony kalendarza"
            sublabel="Opis widoczny na stronie kalendarza"
            value={calendarPageDescription}
            setValue={setCalendarPageDescription}
          />

          <button
            disabled={isUpdating}
            className="btn btn-primary mt-3"
            onClick={handleUpdateCalendar}
          >
            {!isUpdating && "Zapisz"} {isUpdating && <Spinner />}
          </button>
        </>
      )}
    </>
  );
};
